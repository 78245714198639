import { useState } from "react"
import Link from "next/link"
import cn from "classnames"

import Image from "./Image"

import Husky from "public/assets/pets/siberian-husky.png"
import FindLocationIcon from "public/assets/find-location-screenshot.jpg"
import { useDogtownLocations } from "context/dogtownLocations"
import CallToAction from "./CallToAction"
import { useRouter } from "next/router"
import LoadingSpinner from "./LoadingSpinner"
import dynamic from "next/dynamic"

const DynamicMap = dynamic(() => import("./Map"), {
  ssr: false,
})

export default function FindYourLocation() {
  const {
    loading: { value: loading },
    selected: { set: updatedSelectedLocation, name: selectedLocation, nearestLocations = null },
    ...locations
  } = useDogtownLocations()

  // highlight location before making selected location
  const [primedLocation, setPrimedLocation] = useState("")
  const [scrollPosition, setScrollPosition] = useState("")

  return (
    <section id="find-your-location" className="find-your-location px-8 overflow-hidden 2xl:overflow-visible">
      <div className="flex flex-col md:grid xl:grid-flow-col auto-cols-[minmax(500px,1fr)] lg:gap-20 justify-between max-w-screen-xl mx-auto py-8">
        <div className="relative find-your-location-map-container order-2 xl:order-none">
          <DynamicMap
            flyTo={primedLocation}
            setPrimedLocation={setPrimedLocation}
            zoom={8}
            pins={locations.pins}
            copyright="Dogtown"
          />
        </div>
        <div className="location-selector">
          <h2 className="-mt-1 mb-2 leading-none">Select Location</h2>
          <p className="mb-3 lg:max-w-2xl text-s2">
            Select a location to view info and set it as your preferred Dogtown location. To find the location closest
            to you, press the location finder icon{" "}
            <sup>
              {" "}
              (
              <img
                className="inline-block pb-[1px]"
                alt="Select Location Icon"
                src={FindLocationIcon.src}
                width="15"
                height="15"
              />
              )
            </sup>{" "}
            on the map.{" "}
          </p>
          <ul
            className="location-selector__states md:max-h-[504px] pr-4 md:overflow-y-auto list-none pl-0 mb-4"
            onScroll={(e) => {
              setPrimedLocation("")
              setScrollPosition(e.target.scrollTop)
            }}
          >
            {locations.states.map((state) => {
              return (
                <li className="location-selector__state pb-5 lg:last:mb-7" key={state.name}>
                  <h3 className="block uppercase lg:text-m1 pb-4 w-full border-b-[3px] leading-9 border-green-apple decoration-green-apple">
                    <span>{state.name} Locations</span>
                  </h3>
                  <ul className="location-selector__regions grid md:grid-cols-2 list-none pl-0">
                    {state.regions.map(([regionName, _locations]) => {
                      return (
                        <li key={regionName} className="location-selector__region">
                          <h4 className="text-m1 pt-4 mb-2">{regionName}</h4>
                          <ul className="pl-3 font-light list-none">
                            {_locations.map((location, idx) => {
                              // we use this instead of location info from map to ensure same information is set inside as if user opts to use map button
                              // arguably this data should've been one tree to avoid this, but cleaning it up isn't a priority atm
                              const info = locations.pins.find(
                                (locationFromContext) => location.name === locationFromContext.name
                              )

                              let distance = null
                              if (nearestLocations) {
                                const { distanceToLocation } = nearestLocations.find((l) => l.name === info.name)
                                distance = `${distanceToLocation} miles away`
                              }

                              return (
                                <ConfirmLocation
                                  key={idx}
                                  {...{
                                    loading,
                                    selected: selectedLocation === info.name,
                                    updatedSelectedLocation,
                                    scrollPosition,
                                    ...location.content,
                                    setPrimedLocation,
                                    primedLocation,
                                    distance,
                                    info,
                                  }}
                                />
                              )
                            })}
                          </ul>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              )
            })}
          </ul>
          <div className="request-new-location bg-gray-light mt-4 mb-28">
            <div className="flex py-8 px-7 xl:px-12 justify-between">
              <p className=" max-w-[220px] md:max-w-xs">
                If you wish there was a Dogtown in your area, let us know.
                <Link
                  className="request-location-chevron mt-2 block uppercase text-green-pine font-bold text-m3"
                  href="https://app.smartsheet.com/b/form/c2cac2424dec4a5ea1ca954c95e72c74"
                  target="_blank"
                >
                  Request a New Location
                </Link>
              </p>
              <Image
                loader={undefined}
                alt=""
                src={Husky}
                className="-mb-32 -mr-28 -mt-6 select-none aspect-[259/311] w-auto h-[311px]"
                sizes="20vw"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const ConfirmLocation = ({
  name,
  primedLocation,
  setPrimedLocation,
  updatedSelectedLocation,
  selected,
  scrollPosition,
  loading,
  distance,
  info,
}) => {
  const primed = info.name === primedLocation
  const router = useRouter()
  // drop the slug dynamic param since we don't need it
  const { slug, link, ...delegated } = router.query

  // The location-specific link to delegate
  const slugToRouteTo = link ?? ""

  return (
    <li className="confirm-location block mb-4" key={name}>
      <button
        disabled={loading}
        onClick={(e) => {
          e.preventDefault()
          if (primed) {
            setPrimedLocation("")
          } else {
            setPrimedLocation(info.name)
          }
        }}
        className={cn("hover:underline relative disabled:cursor-not-allowed disabled:text-gray-400 transition-all", {
          underline: primed || selected,
          "decoration-green-apple": selected,
        })}
      >
        <LoadingSpinner loading={loading} />
        {info.name}
      </button>

      <div
        style={{ transform: `translateY(-${scrollPosition}px)` }}
        className={cn(
          "location-selector-tooltip absolute mt-2 bg-white isolate px-6 py-5 max-w-xs transition-all duration-500 border-2 border-green-apple shadow-lg transform",
          {
            "opacity-1 translate-y-0 z-10": primed,
            "opacity-0 -translate-y-5 -z-10": !primed,
          }
        )}
      >
        {selected ? (
          <p className="absolute text-s2 top-0 right-2 italic text-green-apple opacity-50">selected</p>
        ) : null}
        {distance ? <p className="distance-to-location text-s1 italic">{distance}</p> : null}
        <h5 className="mb-2 leading-snug">{info.name}</h5>
        <address>{info.address}</address>
        <CallToAction
          disabled={selected}
          className="text-s3 mt-4"
          style="secondary"
          button
          onClick={() => {
            updatedSelectedLocation(info)
            if (router.asPath.includes("[write-a-review]")) {
              window.open(info.reviewLink, "_blank")
              router.push({ pathname: info.slug, query: delegated })
            } else {
              router.replace({
                pathname: `${info.slug}${info.slug.endsWith("/") ? "" : "/"}${slugToRouteTo}`,
                query: delegated,
              })
            }
          }}
        >
          Set Location
        </CallToAction>
      </div>
    </li>
  )
}
