import Link from "next/link"
import cn from "classnames"

import Pin from "public/assets/map-pin.svg"
import Phone from "public/assets/phone.svg"
import SMS from "public/assets/sms.svg"
import getTelLink from "utilities/getTelLink"

export default function LocationSubMenu({ pages, services, enroll, location, visible, mapsLink, primary, sms }) {
  const linkStyles = "uppercase text-s3 text-black font-bold py-2.5 hover:underline"

  return visible ? (
    <section className="bg-green-apple xl:px-8 relative z-50">
      <div className="max-w-screen-xl mx-auto flex items-center xl:justify-between">
        <Link
          href="#select-location"
          className={cn(linkStyles, "text-center w-full xl:text-left xl:w-max px-8 xl:px-0 hidden xl:block")}
        >
          {location.name}
        </Link>
        {mapsLink !== "" && primary !== "" && sms !== "" ? (
          <div className="xl:hidden bg-gray-dark w-full">
            <div className="flex justify-between px-8 py-4 max-w-md mx-auto">
              <Link href={mapsLink} className="group">
                <Pin className="h-6 mb-1 text-white mx-auto group-hover:text-green-apple transition-all" />
                <p className="text-white text-s1 font-bold group-hover:text-green-apple transition-all">Location</p>
              </Link>
              <Link href={getTelLink(primary)} className="group">
                <Phone className="h-6 mb-1 text-white mx-auto group-hover:text-green-apple transition-all" />
                <p className="text-white text-s1 font-bold group-hover:text-green-apple transition-all">Call</p>
              </Link>
              <Link href={getTelLink(sms, true)} className="group">
                <SMS className="h-6 mb-1 text-white mx-auto group-hover:text-green-apple transition-all" />
                <p className="text-white text-s1 font-bold group-hover:text-green-apple transition-all">Text</p>
              </Link>
            </div>
          </div>
        ) : null}
        <div className="items-center hidden xl:flex">
          {services.length > 0 ? (
            <div className="min-w-[146px] group relative">
              <div
                className={cn(
                  linkStyles,
                  "select-none hover:no-underline group-hover:bg-green-pine group-hover:text-white pl-4 pr-7 header-link-arrow-down"
                )}
              >
                Services
              </div>
              <div className="opacity-0 transition-all group-hover:opacity-100 group-hover:block transform origin-top scale-y-0 group-hover:scale-y-100 absolute text-white bg-green-pine top-[2.9rem]">
                {services?.length
                  ? services.map((service, idx) => (
                      <Link
                        href={service.full_slug}
                        key={idx}
                        className="block whitespace-nowrap py-2 pl-4 pr-12 header-link-arrow"
                      >
                        {service.name}
                      </Link>
                    ))
                  : null}
              </div>
            </div>
          ) : null}
          {pages.map(({ full_slug, name }, idx) => (
            <Link href={full_slug} className={cn(linkStyles, "px-4 last:pr-0")} key={idx}>
              {name}
            </Link>
          ))}
          {enroll?.full_slug && enroll.name ? (
            <Link
              href={enroll.full_slug}
              className="text-white bg-gray-dark px-3 uppercase font-bold text-s3 h-full text-just py-2.5 hover:underline"
            >
              Enroll Now
            </Link>
          ) : null}
        </div>
      </div>
    </section>
  ) : null
}
