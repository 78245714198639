import Close from "public/assets/pawprint-x.svg"

export default function Modal({ children, isOpen, closeModal, focusRef }) {
  return isOpen ? (
    <>
      <div
        onClick={() => closeModal()}
        className="overlay animate-fade-in fixed w-full h-full top-0 left-0 right-0 bottom-0 z-[9998] bg-black bg-opacity-50"
      />
      <div
        tabIndex={0}
        ref={focusRef}
        className="modal fixed opacity-0 animate-fade-in overflow-y-auto overflow-x-hidden top-0 xl:top-1/2 lg:left-1/2 transform lg:-translate-x-1/2 xl:-translate-y-1/2 z-[9999] w-full xl:max-w-screen-xl max-h-[100dvh] px-4"
      >
        <div className="bg-white rounded-3xl lg:block w-full my-12 sm:mt-20 sm:mb-4 relative">
          <button
            onClick={closeModal}
            aria-label="Close Modal"
            title="Close Modal"
            className="close-modal-btn absolute -top-16 -right-4 sm:-top-20 sm:-right-2 z-10 scale-[66%] sm:scale-100"
          >
            <Close />
          </button>
          {children}
        </div>
      </div>
    </>
  ) : null
}
