import Head from "next/head"
import "@fontsource/montserrat"
import "@fontsource/montserrat/700.css"

import "styles.css"

import PreviewAlert from "components/PreviewAlert"
import Header from "components/Header"
import Footer from "components/Footer"
import FirstTimeEntryModal from "components/FirstTimeEntryModal"

import useStoryblok from "storyblok/useStoryblok"
import { DogtownLocationsProvider } from "context/dogtownLocations"

function MyApp({ Component, pageProps: { preview, story, resolve_relations, globals, ...remainingProps } }) {
  story = useStoryblok({ story, resolve_relations })

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#603cba" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="facebook-domain-verification" content="kt2apj8ipn1q4dohhoa64cp6htrppc" />
      </Head>
      {/* <FirstTimeEntryModal /> */}

      {preview && <PreviewAlert />}
      <DogtownLocationsProvider buildData={globals.locationData}>
        <Header {...globals.header} />
        <Component story={story} {...remainingProps} />
        <Footer {...globals.footer} />
      </DogtownLocationsProvider>
    </>
  )
}

export default MyApp
