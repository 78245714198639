import cn from "classnames"

export default function LoadingPaws({ status }) {
  return (
    <div
      id="loading-paws"
      aria-hidden
      data-loading={status}
      className={cn("fixed top-0 left-0 bottom-0 right-0 w-full h-[100dvh] bg-white grid place-content-center", {
        // if loading
        "z-50 block": status,
        hidden: !status,
      })}
    >
      <svg
        className="loading-paws"
        width="348"
        height="155"
        viewBox="0 0 348 155"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="paw paw-1">
          <path
            d="M52.2539 86.2931C45.4956 89.1975 40.5965 86.7361 39.4187 82.8318C38.2398 78.9364 41.0656 73.7923 47.9807 72.1703C54.0337 70.7512 59.6382 71.7273 60.816 75.6317C61.9938 79.536 57.9207 83.8618 52.2539 86.2931Z"
            fill="#17B4AE"
          />
          <path
            d="M70.1968 102.268C62.3987 103.733 57.7596 99.9482 57.3686 95.5159C56.9675 91.0915 61.091 86.3094 68.7708 86.2441C75.493 86.1873 81.2081 88.5636 81.599 92.9959C81.9951 97.4243 76.7259 101.039 70.1968 102.268Z"
            fill="#17B4AE"
          />
          <path
            d="M52.4284 126.168C45.6416 123.416 40.7735 125.98 39.6272 129.911C38.482 133.834 41.3495 138.912 48.2765 140.386C54.347 141.674 59.9314 140.574 61.0777 136.643C62.223 132.72 58.106 128.476 52.4284 126.168Z"
            fill="#17B4AE"
          />
          <path
            d="M70.2265 109.807C62.4125 108.515 57.8103 112.407 57.4504 116.84C57.0905 121.274 61.2546 125.963 68.9349 125.857C75.6564 125.768 81.3511 123.257 81.711 118.824C82.0658 114.394 76.7706 110.887 70.2265 109.807Z"
            fill="#17B4AE"
          />
          <path
            d="M36.5204 122.801C40.5008 117.306 46.5621 115.096 46.5621 115.096C53.4468 112.207 53.6662 107.115 53.6413 106.22C53.6576 105.324 53.391 100.239 46.4786 97.5031C46.4786 97.5031 39.9744 95.0273 36.3755 90.0169C33.737 86.3442 28.6854 82.9811 22.4342 85.095C22.4342 85.095 13.5385 87.692 16.4887 98.3189C16.4887 98.3189 17.4802 101.414 18.1945 104.186C18.6026 105.778 18.6084 107.441 18.2193 109.037C17.5261 111.829 16.561 114.94 16.561 114.94C13.6983 125.637 22.6155 128.032 22.6155 128.032C28.8863 130.013 33.8515 126.49 36.5204 122.801Z"
            fill="#17B4AE"
          />
        </g>
        <g className="paw paw-2" transform="translate(65, -55)">
          <path
            d="M52.2539 86.2931C45.4956 89.1975 40.5965 86.7361 39.4187 82.8318C38.2398 78.9364 41.0656 73.7923 47.9807 72.1703C54.0337 70.7512 59.6382 71.7273 60.816 75.6317C61.9938 79.536 57.9207 83.8618 52.2539 86.2931Z"
            fill="#17B4AE"
          />
          <path
            d="M70.1968 102.268C62.3987 103.733 57.7596 99.9482 57.3686 95.5159C56.9675 91.0915 61.091 86.3094 68.7708 86.2441C75.493 86.1873 81.2081 88.5636 81.599 92.9959C81.9951 97.4243 76.7259 101.039 70.1968 102.268Z"
            fill="#17B4AE"
          />
          <path
            d="M52.4284 126.168C45.6416 123.416 40.7735 125.98 39.6272 129.911C38.482 133.834 41.3495 138.912 48.2765 140.386C54.347 141.674 59.9314 140.574 61.0777 136.643C62.223 132.72 58.106 128.476 52.4284 126.168Z"
            fill="#17B4AE"
          />
          <path
            d="M70.2265 109.807C62.4125 108.515 57.8103 112.407 57.4504 116.84C57.0905 121.274 61.2546 125.963 68.9349 125.857C75.6564 125.768 81.3511 123.257 81.711 118.824C82.0658 114.394 76.7706 110.887 70.2265 109.807Z"
            fill="#17B4AE"
          />
          <path
            d="M36.5204 122.801C40.5008 117.306 46.5621 115.096 46.5621 115.096C53.4468 112.207 53.6662 107.115 53.6413 106.22C53.6576 105.324 53.391 100.239 46.4786 97.5031C46.4786 97.5031 39.9744 95.0273 36.3755 90.0169C33.737 86.3442 28.6854 82.9811 22.4342 85.095C22.4342 85.095 13.5385 87.692 16.4887 98.3189C16.4887 98.3189 17.4802 101.414 18.1945 104.186C18.6026 105.778 18.6084 107.441 18.2193 109.037C17.5261 111.829 16.561 114.94 16.561 114.94C13.6983 125.637 22.6155 128.032 22.6155 128.032C28.8863 130.013 33.8515 126.49 36.5204 122.801Z"
            fill="#17B4AE"
          />
        </g>
        <g className="paw paw-3" transform="translate(130, 0)">
          <path
            d="M52.2539 86.2931C45.4956 89.1975 40.5965 86.7361 39.4187 82.8318C38.2398 78.9364 41.0656 73.7923 47.9807 72.1703C54.0337 70.7512 59.6382 71.7273 60.816 75.6317C61.9938 79.536 57.9207 83.8618 52.2539 86.2931Z"
            fill="#17B4AE"
          />
          <path
            d="M70.1968 102.268C62.3987 103.733 57.7596 99.9482 57.3686 95.5159C56.9675 91.0915 61.091 86.3094 68.7708 86.2441C75.493 86.1873 81.2081 88.5636 81.599 92.9959C81.9951 97.4243 76.7259 101.039 70.1968 102.268Z"
            fill="#17B4AE"
          />
          <path
            d="M52.4284 126.168C45.6416 123.416 40.7735 125.98 39.6272 129.911C38.482 133.834 41.3495 138.912 48.2765 140.386C54.347 141.674 59.9314 140.574 61.0777 136.643C62.223 132.72 58.106 128.476 52.4284 126.168Z"
            fill="#17B4AE"
          />
          <path
            d="M70.2265 109.807C62.4125 108.515 57.8103 112.407 57.4504 116.84C57.0905 121.274 61.2546 125.963 68.9349 125.857C75.6564 125.768 81.3511 123.257 81.711 118.824C82.0658 114.394 76.7706 110.887 70.2265 109.807Z"
            fill="#17B4AE"
          />
          <path
            d="M36.5204 122.801C40.5008 117.306 46.5621 115.096 46.5621 115.096C53.4468 112.207 53.6662 107.115 53.6413 106.22C53.6576 105.324 53.391 100.239 46.4786 97.5031C46.4786 97.5031 39.9744 95.0273 36.3755 90.0169C33.737 86.3442 28.6854 82.9811 22.4342 85.095C22.4342 85.095 13.5385 87.692 16.4887 98.3189C16.4887 98.3189 17.4802 101.414 18.1945 104.186C18.6026 105.778 18.6084 107.441 18.2193 109.037C17.5261 111.829 16.561 114.94 16.561 114.94C13.6983 125.637 22.6155 128.032 22.6155 128.032C28.8863 130.013 33.8515 126.49 36.5204 122.801Z"
            fill="#17B4AE"
          />
        </g>
        <g className="paw paw-4" transform="translate(195, -55)">
          <path
            d="M52.2539 86.2931C45.4956 89.1975 40.5965 86.7361 39.4187 82.8318C38.2398 78.9364 41.0656 73.7923 47.9807 72.1703C54.0337 70.7512 59.6382 71.7273 60.816 75.6317C61.9938 79.536 57.9207 83.8618 52.2539 86.2931Z"
            fill="#17B4AE"
          />
          <path
            d="M70.1968 102.268C62.3987 103.733 57.7596 99.9482 57.3686 95.5159C56.9675 91.0915 61.091 86.3094 68.7708 86.2441C75.493 86.1873 81.2081 88.5636 81.599 92.9959C81.9951 97.4243 76.7259 101.039 70.1968 102.268Z"
            fill="#17B4AE"
          />
          <path
            d="M52.4284 126.168C45.6416 123.416 40.7735 125.98 39.6272 129.911C38.482 133.834 41.3495 138.912 48.2765 140.386C54.347 141.674 59.9314 140.574 61.0777 136.643C62.223 132.72 58.106 128.476 52.4284 126.168Z"
            fill="#17B4AE"
          />
          <path
            d="M70.2265 109.807C62.4125 108.515 57.8103 112.407 57.4504 116.84C57.0905 121.274 61.2546 125.963 68.9349 125.857C75.6564 125.768 81.3511 123.257 81.711 118.824C82.0658 114.394 76.7706 110.887 70.2265 109.807Z"
            fill="#17B4AE"
          />
          <path
            d="M36.5204 122.801C40.5008 117.306 46.5621 115.096 46.5621 115.096C53.4468 112.207 53.6662 107.115 53.6413 106.22C53.6576 105.324 53.391 100.239 46.4786 97.5031C46.4786 97.5031 39.9744 95.0273 36.3755 90.0169C33.737 86.3442 28.6854 82.9811 22.4342 85.095C22.4342 85.095 13.5385 87.692 16.4887 98.3189C16.4887 98.3189 17.4802 101.414 18.1945 104.186C18.6026 105.778 18.6084 107.441 18.2193 109.037C17.5261 111.829 16.561 114.94 16.561 114.94C13.6983 125.637 22.6155 128.032 22.6155 128.032C28.8863 130.013 33.8515 126.49 36.5204 122.801Z"
            fill="#17B4AE"
          />
        </g>
        <g className="paw paw-5" transform="translate(260, 0)">
          <path
            d="M52.2539 86.2931C45.4956 89.1975 40.5965 86.7361 39.4187 82.8318C38.2398 78.9364 41.0656 73.7923 47.9807 72.1703C54.0337 70.7512 59.6382 71.7273 60.816 75.6317C61.9938 79.536 57.9207 83.8618 52.2539 86.2931Z"
            fill="#17B4AE"
          />
          <path
            d="M70.1968 102.268C62.3987 103.733 57.7596 99.9482 57.3686 95.5159C56.9675 91.0915 61.091 86.3094 68.7708 86.2441C75.493 86.1873 81.2081 88.5636 81.599 92.9959C81.9951 97.4243 76.7259 101.039 70.1968 102.268Z"
            fill="#17B4AE"
          />
          <path
            d="M52.4284 126.168C45.6416 123.416 40.7735 125.98 39.6272 129.911C38.482 133.834 41.3495 138.912 48.2765 140.386C54.347 141.674 59.9314 140.574 61.0777 136.643C62.223 132.72 58.106 128.476 52.4284 126.168Z"
            fill="#17B4AE"
          />
          <path
            d="M70.2265 109.807C62.4125 108.515 57.8103 112.407 57.4504 116.84C57.0905 121.274 61.2546 125.963 68.9349 125.857C75.6564 125.768 81.3511 123.257 81.711 118.824C82.0658 114.394 76.7706 110.887 70.2265 109.807Z"
            fill="#17B4AE"
          />
          <path
            d="M36.5204 122.801C40.5008 117.306 46.5621 115.096 46.5621 115.096C53.4468 112.207 53.6662 107.115 53.6413 106.22C53.6576 105.324 53.391 100.239 46.4786 97.5031C46.4786 97.5031 39.9744 95.0273 36.3755 90.0169C33.737 86.3442 28.6854 82.9811 22.4342 85.095C22.4342 85.095 13.5385 87.692 16.4887 98.3189C16.4887 98.3189 17.4802 101.414 18.1945 104.186C18.6026 105.778 18.6084 107.441 18.2193 109.037C17.5261 111.829 16.561 114.94 16.561 114.94C13.6983 125.637 22.6155 128.032 22.6155 128.032C28.8863 130.013 33.8515 126.49 36.5204 122.801Z"
            fill="#17B4AE"
          />
        </g>
      </svg>
    </div>
  )
}
