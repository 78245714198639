import { useState, useEffect, useMemo } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import cn from "classnames"

import LocationSubMenu from "components/Header/LocationSubMenu"
import CallToAction from "components/CallToAction"
import { useDogtownLocations } from "context/dogtownLocations"
import MenuButton from "public/assets/hamburger-menu.svg"
import Logo from "public/assets/dogtown.svg"
import PreHeader from "components/Header/PreHeader"
import Modal from "components/FindYourLocationModal"
import FindYourLocation from "components/FindYourLocation"
import SingleMenuItem from "components/Header/SingleMenuItem"
import useModal from "utilities/useModal"

export default function Header({ menu, ...delegated }) {
  const [open, setMobileMenuVisibility] = useState(false)
  const [servicesVisible, setServicesVisible] = useState(false)
  const router = useRouter()

  const modalProps = useModal("#select-location")
  const locations = useDogtownLocations()

  const defaultCurrentLocationPages = useMemo(
    () => ({
      location: {},
      pages: [],
      mapsLink: "",
      primary: "",
      sms: "",
      services: [],
      enroll: { full_slug: "/" },
      visible: false,
    }),
    []
  )

  const [currentLocationPages, setCurrentLocationPages] = useState(defaultCurrentLocationPages)

  useEffect(() => {
    if (locations.selected.address && locations.selected.address !== "Select location") {
      setCurrentLocationPages({
        ...locations.relatedPages[locations.selected.name],
        mapsLink: locations.selected.mapsLink,
        primary: locations.selected.phone.primary,
        sms: locations.selected.phone.sms,
        location: { slug: locations.selected.slug, name: locations.selected.name },
        visible: true,
      })
    } else if (router.asPath === "/") {
      setCurrentLocationPages(defaultCurrentLocationPages)
    } else {
      setCurrentLocationPages(defaultCurrentLocationPages)
    }

    locations.pins.map((location) => {
      if (router.asPath.includes(location.slug.endsWith("/") ? location.slug.slice(0, -1) : location.slug)) {
        setCurrentLocationPages({
          ...locations.relatedPages[location.name],
          mapsLink: location.mapsLink,
          primary: location.phone.primary,
          sms: location.phone.sms,
          location: { slug: location.slug, name: location.name },
          visible: true,
        })
      }
    })
  }, [router, locations, defaultCurrentLocationPages])

  // close the menu when a link is clicked
  useEffect(() => {
    const handleRouteChange = () => setMobileMenuVisibility(false)
    router.events.on("routeChangeComplete", handleRouteChange)
    router.events.on("hashChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
      router.events.off("hashChangeComplete", handleRouteChange)
    }
  }, [router.events])

  // disable scroll when mobile menu is open
  useEffect(() => {
    if (open) document.querySelector("html").style.overflow = "hidden"
    if (!open) document.querySelector("html").style.overflow = ""
  }, [open])

  const Menu = () => (
    <div
      className={cn(
        "py-8 xl:p-0 fixed overflow-scroll xl:overflow-visible xl:static w-full h-[100dvh] xl:h-full xl:w-auto left-0 font-bold z-50 flex flex-col xl:flex-row items-start xl:items-center gap-6 xl:gap-0 bg-white xl:bg-white",
        {
          "hidden xl:flex": !open,
          "top-36": !currentLocationPages.visible,
          "top-60": currentLocationPages.visible,
        }
      )}
    >
      <nav
        aria-label="Main"
        className="menu max-h-[65dvh] overflow-y-auto w-full px-8 pb-12 xl:p-0 xl:max-auto"
        {...delegated}
      >
        <ul aria-label="Top-level Menu Items" className="m-0 xl:flex items-center list-none pl-0">
          {menu.map((item) => (
            <SingleMenuItem currentPath={router.asPath} item={item} key={`menu-item-${item._uid}`} />
          ))}
          {currentLocationPages.visible ? (
            <>
              {currentLocationPages.services.length > 0 ? (
                <>
                  <button
                    aria-label="expand submenu"
                    className="text-s3 xl:hidden w-max"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setServicesVisible(!servicesVisible)
                    }}
                  >
                    Services {servicesVisible ? "-" : "+"}
                  </button>
                  <ul
                    className={cn("submenu transition-all duration-300 overflow-hidden pl-0 list-none", {
                      "max-h-screen": servicesVisible,
                      "max-h-0": !servicesVisible,
                    })}
                  >
                    {currentLocationPages.services.map((service, idx) => (
                      <li key={idx} className="pl-4 my-3 first:pt-0 xl:hidden w-max last:mb-0">
                        <Link href={service.full_slug} className="font-normal header-link-arrow">
                          {service.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
              {currentLocationPages.pages.map((page, idx) => (
                <li key={idx} className="xl:hidden w-max my-3">
                  <Link href={page.full_slug} className="link block xl:inline-block  header-link-arrow">
                    <span className="whitespace-normal sm:whitespace-nowrap font-bold text-s3">{page.name}</span>
                  </Link>
                </li>
              ))}
              {currentLocationPages.enroll?.full_slug && currentLocationPages.enroll?.name ? (
                <CallToAction href={currentLocationPages.enroll.full_slug} className="mt-2 xl:hidden">
                  Enroll Now
                </CallToAction>
              ) : null}
            </>
          ) : null}
        </ul>
      </nav>
    </div>
  )

  return (
    <>
      <Modal {...modalProps}>
        <FindYourLocation />
      </Modal>
      <div className="flex flex-col xl:flex-col-reverse w-full">
        <div className="px-8 relative z-50">
          <div className="max-w-screen-xl mx-auto py-4 flex items-center justify-between">
            <Link aria-label="Go to Home" href={locations.selected.slug ?? "/"}>
              <Logo alt="Dogtown Logo" />
            </Link>
            <button
              className="xl:hidden"
              aria-label={open ? `close menu` : `open menu`}
              onClick={() => setMobileMenuVisibility((prev) => !prev)}
            >
              <MenuButton className={cn("mobile-menu-btn mt-1 cursor-pointer", { open })} />
            </button>
            <Menu />
          </div>
        </div>
        <PreHeader locations={locations} />
      </div>
      <LocationSubMenu {...currentLocationPages} />
    </>
  )
}
