export default function getStoryblokLink({ href, anchor, linktype, cached_url, cachedUrl, url, email } = {}) {
  // Don't ask me why, but Storyblok is inconsistent with this property's case.
  if (cached_url || cachedUrl || href) {
    const linkValue = href ?? cached_url ?? cachedUrl

    // Handle location agnostic links
    if (linkValue.includes("[selected-location]")) {
      if (typeof window !== "undefined") {
        const data = window.localStorage.getItem("location")
        if (data) {
          const location = JSON.parse(data)
          if (location.slug) {
            if (linkValue.includes("[write-a-review]")) {
              return location.reviewLink
            }
            return `${location.slug.startsWith("/") ? "" : "/"}${linkValue.replace(
              "[selected-location]/",
              location.slug
            )}`
          }
        }
      }
      return `#select-location?link=${linkValue.replace("[selected-location]/", "")}`
    }

    if (linktype === "email") return `mailto:${email || url || linkValue}`

    // internal link
    if (linktype === "story" && linkValue !== "") {
      return anchor
        ? `${linkValue.startsWith("/") ? "" : "/"}${linkValue}#${anchor}`
        : `${linkValue.startsWith("/") ? "" : "/"}${linkValue}`
    }

    return linkValue
  }

  return null
}
