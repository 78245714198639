import Link from "next/link"
import getStoryblokLink from "utilities/getStoryblokLink"
import getTelLink from "utilities/getTelLink"
import isInternalLink from "utilities/isInternalLink"
import Logo from "public/assets/footer-logo.svg"
import MapPin from "public/assets/map-pin.svg"
import Phone from "public/assets/phone.svg"
import Twitter from "public/assets/twitter.svg"
import Facebook from "public/assets/facebook.svg"
import Instagram from "public/assets/instagram.svg"

import { useDogtownLocations } from "context/dogtownLocations"
import { useRouter } from "next/router"

export default function Footer({ address, addressLinks, menus, locations }) {
  const router = useRouter()

  return (
    <footer>
      {router.pathname === "/" ? null : <Body {...{ address, addressLinks, menus, locations }} />}
      <CopyrightBar />
    </footer>
  )
}

function Body({ menus, locations }) {
  const location = useDogtownLocations()

  const h3 = "text-white font-bold text-m1 uppercase border-b border-green-apple mb-4 pb-1.5 leading-none"
  const link = "text-white text-s3 font-bold underline hover:no-underline"

  return (
    <div className="bg-gray-dark px-8 py-12">
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 justify-center sm:justify-start gap-8 mx-auto max-w-3xl lg:max-w-screen-xl">
        <div className="space-y-3 lg:max-w-[267px]">
          <Link aria-label="Go to Home" href={location.selected.slug ?? "/"}>
            <Logo className="mx-auto sm:mx-0" alt="Dogtown Logo" />
          </Link>
          {location.selected.slug ? (
            <div className="text-center sm:text-left">
              <MapPin className="inline-block mr-2 w-4 h-5 text-white" />
              <Link
                href={location.selected.mapsLink ?? "/"}
                className={link}
                target={location.selected.mapsLink ? "_blank" : "_self"}
              >
                {location.selected.address}
              </Link>
            </div>
          ) : null}
          {location.selected.phone?.primary ? (
            <div className="text-center sm:text-left">
              <Phone className="inline-block mr-2 w-4 h-5 text-white" />
              {/* TODO? migrate phone datasources to Location content type and populate into location context */}
              <Link href={getTelLink(location.selected.phone.primary)} className={link}>
                {location.selected.phone.primary}
              </Link>
            </div>
          ) : null}

          <SocialMedia services={location.selected.social} />
        </div>
        {menus.map((menu, idx) => {
          return (
            <nav key={idx} className="lg:max-w-[267px]">
              <h3 className={h3}>{menu.name}</h3>
              <ul className="list-none pl-0">
                {menu.content.menu_items.map((link) => {
                  const href = getStoryblokLink(link.link)

                  return (
                    <li key={link.label} className="mb-2">
                      <Link
                        href={href}
                        className="text-white text-s3 font-bold hover:underline"
                        target={isInternalLink(href) ? "_self" : "_blank"}
                      >
                        {link.label}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </nav>
          )
        })}
        {locations.map((state, idx) => (
          <div key={idx} className="lg:max-w-[267px]">
            <nav>
              <h3 className={h3}>{state.name} Locations</h3>
              <ul className="list-none pl-0">
                {state.regions.map(([region, locations]) => (
                  <li key={region}>
                    <h4 className="text-white text-s3 capitalize mb-2">{region}</h4>
                    <ul className="list-none pl-0">
                      {locations.map((location) => (
                        <li key={location.name} className="mb-2 ml-2">
                          <Link href={location.full_slug} className="text-white hover:underline">
                            {location.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </nav>
            {idx === locations.length - 1 ? (
              <div className="border-t border-green-apple mt-8 pt-1">
                <p className="text-white text-s2">If you wish there was a Dogtown in your area, let us know.</p>
                <Link
                  href="https://app.smartsheet.com/b/form/c2cac2424dec4a5ea1ca954c95e72c74"
                  target="_blank"
                  className={link}
                >
                  Request a New Location
                </Link>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

const SocialMedia = ({ services }) => {
  const Icons = {
    facebook: <Facebook />,
    instagram: <Instagram />,
    twitter: <Twitter />,
  }

  return services?.length ? (
    <div className="flex gap-6 justify-center sm:justify-start">
      {services.map(({ service, url }) => {
        const Icon = () => Icons?.[service] ?? null

        return url ? (
          <Link key={url} href={url} target="_blank">
            <Icon />
          </Link>
        ) : null
      })}
    </div>
  ) : null
}

const CopyrightBar = () => (
  <p className="flex flex-col sm:flex-row sm:gap-2 mx-auto bg-green-sea justify-center items-center py-2 px-8">
    <span className="text-s1 text-black">© {new Date().getFullYear()} Dogtown. All Rights Reserved.</span>
    <span className="hidden sm:block text-black text-s1" aria-hidden>
      |
    </span>
    <span className="flex gap-2">
      <Link href="/terms-and-conditions" className="underline hover:no-underline text-s1 text-black">
        Terms & Conditions
      </Link>
      <span className="text-black text-s1" aria-hidden>
        |
      </span>
      <Link href="/privacy-policy" className="underline hover:no-underline text-s1 text-black">
        Privacy Policy
      </Link>
    </span>
  </p>
)
