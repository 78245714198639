import LoadingPaws from "components/LoadingPaws"
import { useRouter } from "next/router"
import { createContext, startTransition, useContext, useEffect, useState } from "react"
import useLocalStorageState from "use-local-storage-state"
import defaultLocationData from "./localstorageSchema"
import maybeMigrate from "./localstorageMigrations"

let loading = true

if (typeof window !== "undefined") {
  let existingLocationData = localStorage.getItem("location")

  if (!existingLocationData) {
    localStorage.setItem("location", JSON.stringify(defaultLocationData))
    existingLocationData = localStorage.getItem("location")
    loading = false
  }

  const possiblyMigratedData = maybeMigrate(existingLocationData)
  localStorage.setItem("location", JSON.stringify(possiblyMigratedData))
}

export const DogtownLocationContext = createContext({
  pins: [],
  states: [],
  nearestLocations: [],
  selected: defaultLocationData,
  loading: { value: loading, set: () => null },
  set: () => null,
})

export const DogtownLocationsProvider = ({ children, buildData }) => {
  const router = useRouter()
  const [location, _setLocation] = useLocalStorageState("location", {
    selected: defaultLocationData,
  })
  const [_loading, _setLoading] = useState(true)

  const [value, _setValue] = useState(() => {
    return {
      loading: {
        value: _loading,
        set: _setLoading,
      },
      ...buildData,
      selected: { ...location, set: _setLocation },
    }
  })

  useEffect(() => {
    startTransition(() => {
      _setValue(({ selected, ...rest }) => ({
        ...rest,
        loading: {
          value: _loading,
          set: _setLoading,
        },
        selected: {
          ...selected,
          ...location,
        },
      }))
    })
  }, [location, _loading])

  useEffect(() => {
    if (value.selected.name === defaultLocationData.name && router.asPath === "/") {
      value.loading.set(false)
    }
  }, [router.asPath, value])

  useEffect(() => {
    if (value.loading.value) {
      document.querySelector("body").style.overflow = "hidden"
    } else {
      document.querySelector("body").style.overflow = ""
    }

    const selectedLocationIsSet = value?.selected?.slug

    function redirectToSelectedLocation(url) {
      if (url === "/" && selectedLocationIsSet) {
        router.push(value.selected.slug)
      }
    }
    redirectToSelectedLocation(router.asPath)
    router.events.on("routeChangeStart", redirectToSelectedLocation)

    function disableLoading() {
      value.loading.set(false)
    }

    if (router.asPath !== "/") value.loading.set(false)

    router.events.on("routeChangeComplete", disableLoading)
    return () => {
      router.events.off("routeChangeComplete", disableLoading)
      router.events.off("routeChangeStart", redirectToSelectedLocation)
    }
  }, [router, value, value.loading.value])

  return (
    <>
      <LoadingPaws status={value.loading.value} />
      <DogtownLocationContext.Provider value={value}>{children}</DogtownLocationContext.Provider>
    </>
  )
}

export const useDogtownLocations = () => useContext(DogtownLocationContext)
