import { useState } from "react"
import Link from "next/link"
import cn from "classnames"
import getStoryblokLink from "utilities/getStoryblokLink"
import isInternalLink from "utilities/isInternalLink"

export default function SingleMenuItem({ item, depth = 1, currentPath }) {
  const [open, setSubMenuAccordionVisibility] = useState(false)

  const href = getStoryblokLink(item.link)

  const MobileAccordionToggle = () =>
    item?.nested_menu_items?.length > 0 ? (
      <button
        aria-label="expand submenu"
        className="xl:hidden font-bold p-2"
        onClick={(e) => {
          e.stopPropagation()
          setSubMenuAccordionVisibility((prev) => !prev)
        }}
      >
        {open ? "-" : "+"}
      </button>
    ) : null

  const SubMenu = ({ nested_menu_items, label, open, depth, currentPath }) => {
    return nested_menu_items.length ? (
      <ul
        aria-label={`${label} menu`}
        className={cn("submenu xl:max-h-screen transition-all overflow-hidden xl:overflow-visible list-none pl-0", {
          "max-h-screen transition-all": open,
          "max-h-0": !open,
        })}
      >
        {nested_menu_items.map((subItem) => (
          <SingleMenuItem
            currentPath={currentPath}
            depth={depth + 1}
            item={subItem}
            key={`menu-item-${subItem._uid}`}
          />
        ))}
      </ul>
    ) : null
  }

  return (
    <li
      aria-label={item.label}
      className={cn("my-3 xl:my-0 w-max", {
        "top-level xl:ml-4": depth === 1,
      })}
    >
      {href ? (
        <Link
          href={href}
          className={cn("link block xl:inline-block header-link-arrow xl:after:hidden", {
            "current-page": currentPath === href,
          })}
          target={isInternalLink(href) ? "_self" : "_blank"}
        >
          <span className="whitespace-normal sm:whitespace-nowrap font-bold text-s3">{item.label}</span>
          <MobileAccordionToggle />
        </Link>
      ) : (
        <div
          aria-label={item.label}
          className="nonlinked select-none"
          onClick={(e) => {
            e.stopPropagation()
            setSubMenuAccordionVisibility((prev) => !prev)
          }}
        >
          {item.label}
          <MobileAccordionToggle />
        </div>
      )}
      <SubMenu currentPath={currentPath} {...{ ...item, open, depth }} />
    </li>
  )
}
