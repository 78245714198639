import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import Modal from "components/Modal"
import CallToAction from "components/CallToAction"
import useModal from "utilities/useModal"
import useLocalStorageStateReady from "utilities/useLocalStorageStateReady"

export default function FirstTimeEntryModal() {
  const modalProps = useModal("#first-time-entry")
  const router = useRouter()

  const [visited, setVisited, isLocalStorageReady] = useLocalStorageStateReady("visited", false)
  const [bypass, setBypass] = useState(false)

  useEffect(() => {
    // Do nothing if the query variables are not yet available
    if (!router.isReady || !isLocalStorageReady) return

    const shouldBypass = Object.hasOwn(router.query, "bypass-modal") && router.query["bypass-modal"] === "true"

    const shouldRenderModal = !visited

    if (shouldRenderModal) {
      // temporarily prevent the modal from rendering this time
      if (shouldBypass) {
        setBypass(true)

        // bail to prevent bypass from being permanent
        return
      }

      // navigate to virtual route and persist state to prevent modal from rendering again
      router.push("#first-time-entry")
      setVisited(true)
    }
  }, [isLocalStorageReady, router, visited, setVisited, bypass, setBypass])

  if (!bypass) {
    return (
      <Modal {...modalProps}>
        <h2 className="text-center mb-6">We&#39;ve Made Some Changes Around Town</h2>
        <p className="mb-8 lg:px-8">
          Our redesigned website now makes it easier to find the Dogtown closest to you, schedule your favorite
          services, and learn about some new ones. Find your main location and take a look around!
        </p>
        <CallToAction href="#select-location" className="table mx-auto">
          Select your location
        </CallToAction>
      </Modal>
    )
  }

  return null
}
