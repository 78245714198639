import { useEffect } from "react"
import useLocalStorageState from "use-local-storage-state"

export default function useLocalStorageStateReady(name, defaultValue = undefined) {
  const [local, setLocal] = useLocalStorageState(name, {
    defaultValue,
  })

  function useIsLocalStorageReady() {
    const [isReady, setIsReady] = useLocalStorageState("__ready")
    useEffect(() => {
      if (isReady) return
      setIsReady(true)
    }, [isReady, setIsReady])
    return Boolean(isReady)
  }

  const isLocalStorageReady = useIsLocalStorageReady()

  return [local, setLocal, isLocalStorageReady]
}
