import Link from "next/link"

export default function PreHeader({ locations }) {
  const linkStyles = "underline text-green-pine font-bold hover:no-underline"
  return (
    <div className="pre-header w-full px-8 bg-gray-light text-s2 font-bold relative z-50">
      <div className="max-w-screen-xl mx-auto flex flex-col xl:flex-row gap-2 items-center justify-between py-3">
        <label htmlFor="location" className="font-bold mx-auto xl:mx-0">
          My Location:{" "}
          <Link
            className="text-green-pine underline underline-offset-1 font-bold hover:no-underline"
            href="#select-location"
          >
            {locations?.selected?.name}
          </Link>
        </label>

        <div>
          Login:{" "}
          <Link className={linkStyles} target="_blank" href="https://secure.petexec.net/login.php">
            Client
          </Link>
          <span className="text-gray-dark"> | </span>
          <Link className={linkStyles} href="/employee-logins">
            Employee
          </Link>
        </div>
      </div>
    </div>
  )
}
