export default function isInternalLink(href) {
  // Location agnostic CTAs
  if (href.includes("[selected-location]")) return true

  // typical relative internal links
  if (href.startsWith("#") || href.startsWith("/")) return true

  // edge cases e.g. protocol-agnostic and other exotic protocols
  return new URL(href).host === process.env.NEXT_PUBLIC_SITE_URL
}
