const schema = {
  version: 1,
  name: "Select location",
  slug: null,
  address: "Select location",
  mapsLink: "",
  reviewLink: "",
  phone: {
    primary: "",
    sms: "",
  },
  email: {
    manager: "",
    frontDesk: "",
  },
  social: [],
}

export default schema
