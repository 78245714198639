const migrations = {
  // introduces versioning
  0: (data) => {
    return {
      ...data,
      version: 1,
    }
  },
  1: ({ relatedPages, ...updatedData }) => {
    return {
      ...updatedData,
      version: 2,
    }
  },
  2: ({ slug, ...data }) => {
    return {
      ...data,
      slug: slug ? (slug.startsWith("/") ? slug : `/${slug}`) : null,
      version: 3,
    }
  },
}

export default function maybeMigrate(data) {
  let transformedData = JSON.parse(data)
  let transformedDataVersion = data?.version ?? 0

  let requiredUpgrade

  while ((requiredUpgrade = migrations[transformedDataVersion]) !== undefined) {
    transformedDataVersion++
    const transformationResult = requiredUpgrade(transformedData)
    transformedData = {
      ...transformationResult,
      version: transformedDataVersion,
    }
  }

  return transformedData
}
